<template>
    <div id="page-article">
        <PageLoader v-if="!article"/>
        <h3 class="page-title">{{ article.title }}</h3>
        <div class="article-post" v-html="article.content"></div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                article: false,
            }
        },

        mounted() {
            axios.get(this.ajaxUrl + '?route=article&slug=' + this.$route.params.slug).then(response => {
                if (response.data.success) {
                    this.article = response.data.data;
                }
            })
        },
    }
</script>

<style lang="scss">
    #page-article {
        .article-post {
            h3.article-title {
                margin-bottom: 0.4rem;
            }

            .article-info {
                font-style: italic;
                margin-bottom: 1.8rem;
                font-size: 0.9em;
            }

            img.right {
                float: right;
                margin: 0 0 10px 20px;
            }

            img.left {
                float: left;
                margin: 0 20px 10px 0;
            }

            blockquote {
                font-size: 1em;
            }
        }
    }
</style>